var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"jobseeker-management-table"},[(_vm.selected.length > 0)?_c('v-btn',{staticStyle:{"margin-bottom":"10px"},attrs:{"align":"right","color":"primary","dark":""},on:{"click":_vm.removeSelected}},[_vm._v(" Remove selected ")]):_vm._e(),_c('v-app',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.$store.getters['admin/jobseekers'],"server-items-length":_vm.$store.getters['admin/jobseekersTotal'],"items-per-page":_vm.params.per_page,"page":_vm.params.page,"show-select":"","single-select":false},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.params, "per_page", $event)},"update:items-per-page":[function($event){return _vm.$set(_vm.params, "per_page", $event)},function($event){return _vm.fetchJobseekers()}],"update:page":[function($event){return _vm.$set(_vm.params, "page", $event)},function($event){return _vm.fetchJobseekers()}],"click:row":_vm.openItem},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"primary","size":"30"}},[(item.profile_img)?_c('img',{attrs:{"src":item.profile_img}}):_vm._e()]),_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]}},{key:"item.current_position",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.idToString("JOB_POSITION", item.current_position))+" ")])]}},{key:"item.plan",fn:function(ref){
var item = ref.item;
return _vm._l((item.plan),function(plan){return _c('div',{key:plan.id},[(plan)?_c('v-chip',{staticClass:"pl-5 pr-5",attrs:{"color":_vm.plans[plan.plan_slug] && _vm.plans[plan.plan_slug].color
                ? _vm.plans[plan.plan_slug].color
                : '#ffffff',"text-color":_vm.plans[plan.plan_slug] && _vm.plans[plan.plan_slug].text
                ? _vm.plans[plan.plan_slug].text
                : '#000000'}},[_vm._v(" "+_vm._s(plan && plan.name ? plan.name : "-")+" ")]):_vm._e()],1)})}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t("deleteThisItem")))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v(_vm._s(_vm.$t("ok")))]),_c('v-spacer')],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDeleteSelected),callback:function ($$v) {_vm.dialogDeleteSelected=$$v},expression:"dialogDeleteSelected"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t("deleteThisItem")))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDeleteSelected}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteSelectedItemConfirm}},[_vm._v(_vm._s(_vm.$t("ok")))]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="jobseeker-management-table">
    <v-btn
      align="right"
      v-if="selected.length > 0"
      color="primary"
      dark
      v-on:click="removeSelected"
      style="margin-bottom: 10px;"
    >
      Remove selected
    </v-btn>
    <v-app>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="$store.getters['admin/jobseekers']"
        :server-items-length="$store.getters['admin/jobseekersTotal']"
        :items-per-page.sync="params.per_page"
        :page.sync="params.page"
        @update:page="fetchJobseekers()"
        @update:items-per-page="fetchJobseekers()"
        @click:row="openItem"
        show-select
        :single-select="false"
      >
        <template #item.name="{ item }">
          <v-avatar color="primary" size="30" class="mr-2">
            <img v-if="item.profile_img" :src="item.profile_img" />
          </v-avatar>
          {{ item.first_name }} {{ item.last_name }}
        </template>
        <template #item.current_position="{ item }">
          <span>
            {{ idToString("JOB_POSITION", item.current_position) }}
          </span>
        </template>
        <template #item.plan="{ item }">
          <div v-for="plan in item.plan" :key="plan.id">
            <v-chip
              v-if="plan"
              class="pl-5 pr-5"
              :color="
                plans[plan.plan_slug] && plans[plan.plan_slug].color
                  ? plans[plan.plan_slug].color
                  : '#ffffff'
              "
              :text-color="
                plans[plan.plan_slug] && plans[plan.plan_slug].text
                  ? plans[plan.plan_slug].text
                  : '#000000'
              "
            >
              {{ plan && plan.name ? plan.name : "-" }}
            </v-chip>
          </div>
        </template>
      </v-data-table>
    </v-app>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">{{ $t("deleteThisItem") }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">{{
            $t("cancel")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{
            $t("ok")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteSelected" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">{{ $t("deleteThisItem") }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDeleteSelected">{{
            $t("cancel")
          }}</v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteSelectedItemConfirm"
            >{{ $t("ok") }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "JobseekerManagementTable",

  data() {
    return {
      params: {
        page: 1,
        per_page: 10,
        user_type: "jobseeker",
      },
      dialog: false,
      dialogDelete: false,
      dialogDeleteSelected: false,
      singleSelect: false,
      selected: [],
      headers: [
        {
          text: this.$t("name"),
          value: "name",
          width: "30%",
        },
        {
          text: this.$t("address"),
          value: "address",
          width: "30%",
        },
        {
          text: this.$t("position"),
          value: "current_position",
          width: "20%",
        },
        {
          text: this.$t("plan"),
          value: "plan",
          width: "20%",
        },
      ],
      plans: {
        basic: {
          color: "#F2E7FC",
          text: "#8C18E2",
        },
        highlighted: {
          color: "#F2E7FC",
          text: "#8C18E2",
        },
        "all-inclusive": {
          color: "#E1F5FD",
          text: "#4A4DE6",
        },
        premium: {
          color: "#FBEDE7",
          text: "#FF6422",
        },
      },
    };
  },

  created() {
    this.fetchJobseekers();
  },

  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editing = false;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    closeDeleteSelected() {
      this.dialogDeleteSelected = false;
    },
    removeSelected: function() {
      this.dialogDeleteSelected = true;
    },
    fetchJobseekers() {
      store.dispatch("admin/fetchJobseekers", this.params);
    },
    openItem(item) {
      this.$router.push({
        name: "AdminJobseekerManagementUser",
        params: { id: item.id },
      });
    },

    deleteItemConfirm() {
      store.dispatch("admin/userDeleteListItem", {
        item: this.editedItem,
        params: this.params,
      });
      this.closeDelete();
    },
    deleteSelectedItemConfirm() {
      let selected = [];
      this.selected.map((e) => {
        selected.push(e.id);
      });

      store.dispatch("admin/userDeleteSelectedListItem", {
        items: selected,
        params: this.params,
      });

      this.closeDeleteSelected();
    },
  },
  watch: {
    dialog(val) {
      alert(this.selected);
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogDeleteSelected(val) {
      val || this.closeDeleteSelected();
    },
  },
};
</script>
